import React from 'react';
import { connect } from 'react-redux';

import { withAuthProps } from '../../../common/hooks/withAuth';
import { ReduxState } from '../../../reducers';
import { withRouter, WithRouterProps } from '../../../common/hooks/withParams';
import { RCONDetails, ServerDetailsModel, ServerModel, ServerSessionDTO } from '../../../api/server.types';
import { Page } from '../../../components/common/Page';
import { PlayerCards } from './PlayerCards';
import { PlayerDetails } from '../../../api/server-players.types';
import { listPlayers } from '../../../api/server-players';
import { addError, addSuccess } from '../../../actions/notifications';
import { withSmallScreenCheck, WithSmallScreenCheckProps } from '../../../common/hooks/withSmallScreenCheck';
import { PlayersTable } from './PlayersTable';
import { ServerActions } from './ServerActions';
import { Box, Button, Link, Typography } from '@mui/material';
import { PlayerVerificationDialog } from './PlayerVerification';
import { SelectOption } from '../../../components/common/Select';
import { loadLocations } from '../../../actions/server-settings';
import { Location } from '../../../api/server-settings.types';
import { ADMIN_TABS } from '../../../api/server-roles.types';
const POLL_INTERVAL_MS = 1000 * 5;

interface ReduxStateProps {
  session?: ServerSessionDTO;
  serverDetails?: ServerDetailsModel;
  locations: SelectOption<Location>[]
  rconUpdating: boolean;
  loading: boolean;
}

interface ReduxActionProps {
  addError: (message: string) => void;
  addSuccess: (message: string) => void;
  loadLocations: (serverUuid: string) => void;
}

interface ComponentProps {
  server: ServerModel;
  rcon: RCONDetails;
}

interface ComponentState {
  players: PlayerDetails[];
  loadingPlayers: boolean;
  showPlayerVerificationDialog: boolean;
 }

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps & WithRouterProps & WithSmallScreenCheckProps;

class ServerPlayersTabComponent extends React.Component<CompositeProps, ComponentState> {
  pollingInterval: any;
  state = {
    players: [],
    loadingPlayers: false,
    showPlayerVerificationDialog: false,
  }

  startPollingInterval = () => {
    this.pollingInterval = setInterval(this.pollPlayers, POLL_INTERVAL_MS);
  }

  clearPollingInterval = () => {
    clearInterval(this.pollingInterval);
  }

  pollPlayers = async() => {
    if (!this.props.serverDetails?.server) {
      return;
    }
    try {
      const players = await listPlayers(this.props.serverDetails.server.uuid)
      this.setState({ players: players ?? []});
    } catch (e: any) {
      this.clearPollingInterval();
      if (e.userFriendly) {
          this.props.addError(e.message);
      } else {
        this.props.addError('An error occurred while loading server players.');
      }
    }
  }

  loadServerPlayers = async() => {
    if (!this.props.serverDetails?.server) {
      return;
    }
    try {
      this.clearPollingInterval();
      this.setState({ loadingPlayers: true });
      const players = await listPlayers(this.props.serverDetails.server.uuid)
      this.setState({ players: players ?? [], loadingPlayers: false });
      this.startPollingInterval();
    } catch (e: any) {
      if (e.userFriendly) {
          this.props.addError(e.message);
      } else {
        this.props.addError('An error occurred while loading server players.');
      }
    }
  }

  componentDidMount(): void {
    this.loadPageData();
  }

  componentWillUnmount(): void {
      if(this.pollingInterval) {
        this.clearPollingInterval();
      }
  }

  loadPageData = () => {
    if (!this.props.serverDetails?.server?.uuid) {
      return;
    }

    this.loadServerPlayers();    
    this.props.loadLocations(this.props.serverDetails.server.uuid);
  }

  componentDidUpdate(prevProps: CompositeProps): void {
    if (!prevProps.serverDetails?.server?.uuid && this.props.serverDetails?.server?.uuid) {
      this.loadPageData();
    }
  }
  
  onShowPlayerVerificationDialog = () => {
    this.setState({ showPlayerVerificationDialog: true });
  }

  onClosePlayerVerificationDialog = () => {
    this.setState({ showPlayerVerificationDialog: false });
  }

  onShowAlreadyVerifiedDialog = () => {
    this.props.addSuccess('Your agid has been verified.');
    setTimeout(() => {
      window.location.reload();
    }, 1000);
  }

  onPlayerVerificationError = (message: string) => {
    this.onClosePlayerVerificationDialog();
    this.props.addError(message);
  }


  render() {
    const { players, loadingPlayers, showPlayerVerificationDialog } = this.state;
    const { serverDetails, isSmallScreen, locations, session } = this.props;
    if (!serverDetails?.server) {
      return null;
    }
    const hasAdminTabs = ADMIN_TABS.some(tab => serverDetails.server.permissions.includes(tab));
    const inAdmin = this.props.location.pathname.includes('admin');
    return (
      <Page sx={{ display: 'flex', flex: 1, flexDirection: 'column', height: '88%' }}>
          <PlayerVerificationDialog 
            visible={showPlayerVerificationDialog}
            onPlayerVerified={this.onShowAlreadyVerifiedDialog}
            onClose={this.onClosePlayerVerificationDialog}
            onError={this.onPlayerVerificationError}
          />
          <Box sx={{ display: 'flex', width: '100%', justifyContent: hasAdminTabs? 'space-between' : 'flex-end'}}>
            {hasAdminTabs && (
              <Button sx={{ mr: 2 }} variant="outlined" color="primary" href={inAdmin ? `server/${serverDetails?.server?.uuid}/home` : `server/${serverDetails?.server?.uuid}/admin`}>
                {inAdmin ? 'Exit Admin' : 'Admin View '}
              </Button>
            )}
            <Button variant="outlined" color="primary" onClick={this.onShowPlayerVerificationDialog}>Verify Player</Button>
          </Box>
          <Box sx={{ mt: 2, mb: 2 }}>
            <ServerActions server={serverDetails.server} locations={locations}/>
          </Box>
          <Box sx={{ display: 'flex', flex: 1, flexDirection: 'column', justifyContent: 'space-between', alignItems: 'center' }}>
            {isSmallScreen && <PlayerCards loading={loadingPlayers} server={serverDetails.server} players={players} locations={locations} session={session} />}
            {!isSmallScreen && <PlayersTable loading={loadingPlayers} server={serverDetails.server} players={players} locations={locations} session={session} />}
            <Link href={`server/${serverDetails.server.uuid}/leaderboard`} sx={{ alignSelf: 'center' }}>
              <Typography variant="body2" sx={{ color: 'primary.main', fontSize: 20 }}>View Leaderboard</Typography>
            </Link>
          </Box>
      </Page>
    )
  }
}


const mapStateToProps = ({ servers, serverSettings }: ReduxState) => {
  const { serverDetail } = servers;
  const serverUuid = serverDetail?.server?.uuid;
  const locations = serverSettings[serverUuid ?? '']?.locations ?? [] as SelectOption<Location>[];

  return {
    session: serverDetail?.session,
    serverDetails: servers.serverDetail,
    loading: servers.loadingServerDetails,
    rconUpdating: servers.rconUpdating,
    locations,
  }
};

export const ServerPlayersTab = withSmallScreenCheck(withRouter(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { addError, addSuccess, loadLocations })(ServerPlayersTabComponent)));