import axios from 'axios'

import { FriendlyApiError, GenericError } from './types'
import { VerificationCode } from './player-verification.types'
import { KillDeathStatsItem, ServerKillDeathStats } from './kill-death-stats.types'

const SERVER_ENDPOINT = `/api/server`
const SERVER_KILL_STATS = `/kills/stats`
const USER_STATS_ENDPOINT = `/user/stats`
const USER_CURRENT_LOGIN_ENDPOINT = `/user/stats/latest`

export const getPlayerKillStats = async(serverUuid: string): Promise<KillDeathStatsItem[]> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}${USER_STATS_ENDPOINT}`)
        const { success, items } = data

        if (!success) {
            throw new GenericError('Failed to retrieve player kill stats')
        }

        return items as KillDeathStatsItem[]
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const getPlayerKillStatsForActiveLogin = async(serverUuid: string): Promise<KillDeathStatsItem[]> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}${USER_CURRENT_LOGIN_ENDPOINT}`)
        const { success, items } = data

        if (!success) {
            throw new GenericError('Failed to retrieve player kill stats')
        }

        return items as KillDeathStatsItem[]
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const getServerKillStats = async(serverUuid: string): Promise<ServerKillDeathStats> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}${SERVER_KILL_STATS}`)
        const { success, item } = data

        if (!success) {
            throw new GenericError('Failed to retrieve player kill stats')
        }

        return item as ServerKillDeathStats
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}