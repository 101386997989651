import axios from 'axios';
import { CreatorModeItem, CreatorModeOption } from './creator-modes.types';
import { ServerSettings } from './server-settings.types';
import { FriendlyApiError, GenericError } from './types';
import { Location } from './locations.types';


const SERVER_ENDPOINT = `/api/server`
const SETTINGS_ENDPOINT = `settings`

const CUSTOM_LOCATIONS_ENDPOINT = `custom-locations`
const LOCATIONS_ENDPOINT = `commands/locations`
const RESPAWN_POINT_ENDPOINT = `${SETTINGS_ENDPOINT}/respawn-point`
const CUSTOM_RESPAWN_POINT_ENDPOINT = `${SETTINGS_ENDPOINT}/custom-respawn-point`
const RESPAWN_GROWTH_ENDPOINT = `${SETTINGS_ENDPOINT}/respawn-growth`
const RESPAWN_MARKS_ENDPOINT = `${SETTINGS_ENDPOINT}/respawn-marks`

export const getServerSettings = async(serverUuid: string): Promise<ServerSettings> => {
    try {
        const { data } = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${SETTINGS_ENDPOINT}`);
        const { item } = data;
    
        return item as ServerSettings;

    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to retrieve server settings')
    }
}

export const loadLocations = async(serverUuid: string, shouldLoadServerLocations: boolean, shouldLoadCustomLocations: boolean): Promise<Location[]> => {
    const promises = [] as Promise<Location[]>[];
    if (shouldLoadServerLocations) {
        promises.push(loadServerLocations(serverUuid));
    }
    if (shouldLoadCustomLocations) {
        promises.push(loadCustomLocations(serverUuid));
    }

    try {
        const [serverLocations = [], customLocations = []] = await Promise.all(promises)

        return [...customLocations, ...serverLocations] 
    } catch (e) {
        if (e instanceof FriendlyApiError) {
            throw e;
        }

        throw new GenericError('Failed to load locations')
    }

}

export const loadCustomLocations = async(serverUuid: string): Promise<Location[]> => {
    try {
        const {data} = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${CUSTOM_LOCATIONS_ENDPOINT}`);
        const {items} = data;

        return items
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to load custom locations')
    }
}


export const loadServerLocations = async(serverUuid: string): Promise<Location[]> => {
    try {
        const {data} = await axios.get(`${SERVER_ENDPOINT}/${serverUuid}/${LOCATIONS_ENDPOINT}`);
        const {items} = data;

        return items.map((location: string) => {
            return {
                name: location.match(/\S+/g)?.join(' '),
                location,
                map: 'Current',
            }
        });
    } catch (e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw new GenericError('Failed to load server locations')
    }
}

export const updateRespawnLocation = async(serverUuid: string, location: string): Promise<void> => {
    try {
        await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${RESPAWN_POINT_ENDPOINT}`, {location});
    } catch (e) {
        console.error(e);
    }
}

export const updateCustomRespawnLocation = async(serverUuid: string, locationId: string): Promise<void> => {
    try {
        await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${CUSTOM_RESPAWN_POINT_ENDPOINT}`, {locationId});
    } catch (e) {
        console.error(e);
    }
}

export const updateRespawnGrowth = async(serverUuid: string, growth: string): Promise<void> => {
    try {
       await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${RESPAWN_GROWTH_ENDPOINT}`, {growth});
    } catch (e) {
        console.error(e);
    }
}

export const updateRespawnMarks = async(serverUuid: string, marks: number): Promise<void> => {
    try {
       await axios.post(`${SERVER_ENDPOINT}/${serverUuid}/${RESPAWN_MARKS_ENDPOINT}`, { marks });
    } catch (e) {
        console.error(e);
    }
}
