import * as React from 'react';
import { Box, Tabs, Tab } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { TabItem, TABS } from '../../../api/admin.types';
import { withRouter, WithRouterProps } from '../../../common/hooks/withParams';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { withSmallScreenCheck, WithSmallScreenCheckProps } from '../../../common/hooks/withSmallScreenCheck';

interface ReduxStateProps {
  user?: any;
}

interface ReduxActionProps {
}

interface ComponentProps {}

interface ComponentState {
  tabIndex?: number;
  tabs: TabItem[];
}

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps & WithRouterProps & WithSmallScreenCheckProps;


class Admin extends React.Component<CompositeProps, ComponentState> {
  state = {
    tabIndex: undefined,
    tabs: [] as TabItem[],
  }

  hasPermission = (scope: string, required: string[]) => {
    return required.some((permission) => scope.includes(permission));
  }
  
  filterTabs = (scope: string) => {
    return TABS.filter((tab) => this.hasPermission(scope, tab.requires));
  }

  updateTabs = () => {
    const { user } = this.props;
    if (!user) {
      return;
    }

    const tabs = this.filterTabs(user.scope);
    const currentEndpoint = window.location.pathname.replace('/admin/', '');
    let tabIndex = tabs.findIndex((tab) => tab?.endpoint === currentEndpoint);
    if (tabIndex === -1) {
      tabIndex = 0;
    }
    this.setState({ tabs, tabIndex });
  }

  navigateToTab = (tabIndex: number) => {
    const { user, navigate } = this.props;
    if (!user) {
      return;
    }
    navigate(`/admin/${this.state.tabs[tabIndex].endpoint}`);
  }

  componentDidMount(): void {
    this.updateTabs();
  }

  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
    const { user } = this.props;
    const { user: prevUser } = prevProps;

    if (this.props.params.serverUuid !== prevProps.params.serverUuid) {
      this.loadAdminDetails();
    }

    if (user !== prevUser) {
      this.updateTabs();
    }

    if (this.state.tabIndex !== prevState.tabIndex) {
      this.navigateToTab(this.state.tabIndex ?? 0);
    }
  }

  loadAdminDetails = async() => {
  }

  handleChange = (_: React.SyntheticEvent, newValue: number) => {
    this.setState({ tabIndex: newValue });
  };

  render() {
    const { tabIndex, tabs } = this.state;
    const { user, isSmallScreen } = this.props;
    
    if (!user) {
      return (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: '10rem' }}>
          <ClipLoader />
        </Box>
      );
    }

    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          height: '100vh',
        }}
      >
        <Tabs
          orientation={isSmallScreen ? 'horizontal' : 'vertical'}
          variant="scrollable"
          value={tabIndex}
          onChange={this.handleChange}
          aria-label="Admin tabs"
          sx={{
            borderRight: isSmallScreen ? 0 : 1,
            borderBottom: isSmallScreen ? 1 : 0,
            borderColor: 'divider',
            width: isSmallScreen ? '100%' : '200px',
          }}
        >
          {tabs.map((tab) => (
            <Tab key={tab.label} label={tab.label} />
          ))}
        </Tabs>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Outlet />
        </Box>
      </Box>
    );
  }
}


const mapStateToProps = ({ auth }: ReduxState) => {
  const { user } = auth;
  return {
    user,
  }
};

export const AdminComponent = withSmallScreenCheck(withRouter(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps)(Admin)));