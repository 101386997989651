declare global {
  interface Window {
    SpeechRecognition: any;
    webkitSpeechRecognition: any;
  }
}

import React, { Component } from 'react';
import { IconButton, Fade } from '@mui/material';
import { Mic, MicOff } from '@mui/icons-material';
import { styled, keyframes } from '@mui/material/styles';

interface VoiceCommandCardProps {
  /**
   * Fired when the user finishes speaking, providing a Blob of audio data.
   * Typically used to upload audio to a server (e.g., for Whisper).
   */
  onRecordingCaptured: (audioBlob: Blob) => Promise<string | Buffer>;
}

interface VoiceCommandCardState {
  /**
   * True if we are currently recording audio.
   */
  recording: boolean;

  /**
   * An optional error message if recording fails.
   */
  error?: string;

  /**
   * True if the audio clip has been sent to the server and is still being processed.
   */
  processing: boolean;
}

/**
 * A minimal, compact component that toggles audio recording in the browser.
 * Clicking the microphone icon starts or stops recording.
 * A small floating waveform animates when recording.
 * When recording ends, we produce a Blob of audio data.
 */
export class VoiceCommandCard extends Component<
  VoiceCommandCardProps,
  VoiceCommandCardState
> {
  private audioContext: AudioContext | null = null;
  private mediaRecorder: MediaRecorder | null = null;
  private chunks: BlobPart[] = [];

  constructor(props: VoiceCommandCardProps) {
    super(props);
    this.state = {
      recording: false,
      error: undefined,
      processing: false,
    };
    this.audioContext = new (window.AudioContext || (window as any).webkitAudioContext)();
  }

  /**
   * Checks the user's microphone permission status before attempting to record.
   * Returns true if permission is granted or if the browser doesn't support the Permissions API.
   * Returns false if the user has already denied permission.
   */
  private checkMicrophonePermissions = async (): Promise<boolean> => {
    if (navigator.permissions && navigator.permissions.query) {
      try {
        const permissionStatus = await navigator.permissions.query({
          name: 'microphone' as PermissionName,
        });
        if (permissionStatus.state === 'denied') {
          this.setState({
            error: 'Microphone access is denied. Please enable it in your browser settings.',
          });
          return false;
        }
      } catch (err) {
        console.warn('Permissions API not fully supported; proceeding to getUserMedia.', err);
      }
    }
    return true;
  };

  /**
   * Start recording audio from the user's microphone.
   */
  startRecording = async () => {
    // If we are currently processing a previous request, ignore clicks
    if (this.state.processing) return;

    const canRecord = await this.checkMicrophonePermissions();
    if (!canRecord) return;

    try {
      const stream = await navigator.mediaDevices.getUserMedia({ audio: true });
      this.mediaRecorder = new MediaRecorder(stream);
      this.chunks = [];

      this.mediaRecorder.onstart = () => {
        this.setState({ recording: true, error: undefined });
      };
      this.mediaRecorder.ondataavailable = (event) => {
        if (event.data.size > 0) {
          this.chunks.push(event.data);
        }
      };
      this.mediaRecorder.onstop = () => {
        const audioBlob = new Blob(this.chunks, { type: 'audio/webm' });
        // Once recording stops, immediately handle the captured audio
        this.onRecordingCaptured(audioBlob);
      };

      this.mediaRecorder.start();
      this.setState({ error: undefined });
    } catch (err: any) {
      console.error('Error accessing microphone:', err);
      this.setState({ error: 'Unable to access microphone' });
    }
  };

  /**
   * Stop recording audio.
   */
  stopRecording = () => {
    if (this.mediaRecorder && this.state.recording) {
      this.mediaRecorder.stop();
      this.mediaRecorder = null;
    }
    this.setState({ recording: false });
  };

  playTextResponse = (response: string) => {
    const synth = window.speechSynthesis;
    const utterance = new SpeechSynthesisUtterance(response);
    synth.speak(utterance);
  };

  /**
   * Passes the audio blob up to the parent component and optionally
   * plays the processed audio response from the server.
   */
  onRecordingCaptured = async (audioBlob: Blob) => {
    // Indicate we're currently processing the prompt
    this.setState({ processing: true });

    const response = await this.props.onRecordingCaptured(audioBlob);
    // If we got a valid response
    if (response instanceof ArrayBuffer) {
      this.playAudioResponse(response);
    } else {
      this.playTextResponse(response as string);
    }
    // Turn off the processing indicator
    this.setState({ processing: false });
  };

  /**
   * Decodes and plays audio from an ArrayBuffer (e.g., server response).
   */
  playAudioResponse = (audioBuffer: ArrayBuffer) => {
    if (!this.audioContext) return;
    this.audioContext.decodeAudioData(audioBuffer, (decoded) => {
      const source = this.audioContext!.createBufferSource();
      source.buffer = decoded;
      source.connect(this.audioContext!.destination);
      source.start(0);
    });
  };

  render() {
    const { recording, error, processing } = this.state;

    return (
      <MicWrapper>
        {/* The microphone button */}
        <PulsingIconButton
          onClick={recording ? this.stopRecording : this.startRecording}
          color={recording ? 'error' : 'primary'}
          disabled={processing} // Disable if we're processing a previous prompt
          $isProcessing={processing} // Custom prop for styling
          sx={{
            position: 'relative',
            zIndex: 2,
            boxShadow: (recording || processing)
              ? '0 0 15px rgba(255, 0, 0, 0.6)'
              : '0 0 10px rgba(0, 0, 0, 0.15)',
            transition: 'all 0.3s ease',
          }}
        >
          {(recording || processing) ? <MicOff /> : <Mic />}
        </PulsingIconButton>

        {/* Animated waveform overlay when recording */}
        <Fade in={recording} unmountOnExit>
          <WaveOverlay>
            <ListeningWave>
              <WaveBar delay={0} />
              <WaveBar delay={0.1} />
              <WaveBar delay={0.2} />
              <WaveBar delay={0.3} />
              <WaveBar delay={0.4} />
              <WaveBar delay={0.5} />
              <WaveBar delay={0.6} />
            </ListeningWave>
          </WaveOverlay>
        </Fade>

        {error && <ErrorBubble>{error}</ErrorBubble>}
      </MicWrapper>
    );
  }
}

// ---- Styled Components ----

const MicWrapper = styled('div')(() => ({
  position: 'fixed',
  bottom: '24px',
  left: '50%',
  transform: 'translateX(-50%)',
  display: 'inline-flex',
  zIndex: 9999,
}));

const WaveOverlay = styled('div')(() => ({
  position: 'absolute',
  top: '-60px',
  left: '50%',
  transform: 'translateX(-50%)',
  width: '120px',
  height: '50px',
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  pointerEvents: 'none',
  zIndex: 1,
}));

const ListeningWave = styled('div')(() => ({
  display: 'flex',
  alignItems: 'flex-end',
  height: '100%',
  width: '100%',
  justifyContent: 'space-around',
}));

interface WaveBarProps {
  delay: number;
}

const WaveBar = styled('div')<WaveBarProps>(({ theme, delay }) => ({
  width: 8,
  backgroundColor: theme.palette.primary.main,
  borderRadius: 2,
  animation: 'wave 1.2s infinite ease-in-out',
  animationDelay: `${delay}s`,
  '@keyframes wave': {
    '0%': {
      height: '20%',
    },
    '50%': {
      height: '100%',
    },
    '100%': {
      height: '20%',
    },
  },
}));

/**
 * A bubble-shaped container for displaying error messages.
 */
const ErrorBubble = styled('div')(({ theme }) => ({
  position: 'absolute',
  bottom: '70px',
  left: '50%',
  transform: 'translateX(-50%)',
  backgroundColor: theme.palette.error.light,
  color: theme.palette.error.contrastText,
  border: `1px solid ${theme.palette.error.main}`,
  borderRadius: 6,
  padding: '12px 16px',
  boxShadow: '0 2px 6px rgba(0, 0, 0, 0.2)',
  width: 300,
  textAlign: 'center',
  lineHeight: 1.4,

  '&::after': {
    content: '""',
    position: 'absolute',
    bottom: '-8px',
    left: 'calc(50% - 8px)',
    width: 0,
    height: 0,
    borderLeft: '8px solid transparent',
    borderRight: '8px solid transparent',
    borderTop: `8px solid ${theme.palette.error.light}`,
  },
}));

/** 
 * A keyframes animation to create a pulsing effect:
 * scale up, then back down, repeatedly.
 */
const pulse = keyframes`
  0% {
    transform: scale(1.0);
  }
  50% {
    transform: scale(1.1);
  }
  100% {
    transform: scale(1.0);
  }
`;

/**
 * Styled IconButton that pulses when the component is in 'processing' mode.
 */
const PulsingIconButton = styled(IconButton, {
  shouldForwardProp: (prop) => prop !== '$isProcessing',
})<{ $isProcessing: boolean }>(({ $isProcessing }) => ({
  // Only apply pulsing animation if isProcessing is true
  animation: $isProcessing ? `${pulse} 1.5s ease-in-out infinite` : 'none',
}));
