import { Location } from "../api/locations.types";
import { LOCATIONS_RECEIVED, SETTINGS_UPDATE } from "../actions/types";
import { SelectOption } from "../components/common/Select";

export interface ServerSettings {
    respawn_point?: string;
    respawn_growth?: string;
    respawn_marks?: number;
    locations: SelectOption<Location>[];
}

export interface ServerSettingsState {
    [key: string]: ServerSettings;
}

export const SettingsSettingsInitialState: ServerSettingsState = {
    
};

export const ServerSettingsReducer = (state = SettingsSettingsInitialState, action: any) => {
    const { type, payload } = action;

    switch (type) {
        case LOCATIONS_RECEIVED:
            return {
                ...state,
                [payload.serverUuid]: {
                    ...state[payload.serverUuid],
                    locations: payload.locations,
                }
            };
        case SETTINGS_UPDATE:
            return {
                ...state,
                [payload.serverUuid]: {
                    ...state[payload.serverUuid],
                    respawn_point: payload.settings.respawn_point ?? state[payload.serverUuid]?.respawn_point,
                    respawn_growth: payload.settings.respawn_growth ?? state[payload.serverUuid]?.respawn_growth,
                    respawn_marks: payload.settings.respawn_marks ?? state[payload.serverUuid]?.respawn_marks,
                }
            };
    }
    return state;
}