import React from 'react';
/* eslint-disable react/prefer-stateless-function */
import { Navigate, NavigateFunction } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";

import { withRouter } from '../../common/hooks/withParams';
import { connect } from 'react-redux';
import { addError } from '../../actions/notifications';
import { withSetAuthProps, withSetAuth } from '../../common/hooks/withSetAuth';
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { Box, Typography } from '@mui/material';
import { loginDiscord, registerDiscord } from '../../api';
import { FriendlyApiError } from '../../api/types';

export const INVITE_CODE_ERROR_MAPPER: { [key: string]: string } = {
  invalid_invitation_code: 'Invalid invitation code',
  invitation_revoked: 'Invitation has been revoked.',
  invitation_claimed: 'Invitation has already been used.',
  invitation_expired: 'Invitation has expired',
  unable_to_validate_invitation: 'Unable to validate invitation',
  registration_invite_only: 'Registration is invite only, at the moment.',
};

export interface ComponentState {
  loading: boolean;
  loadingMessage?: string;
}
export interface ComponentProps extends withAuthProps, withSetAuthProps {
  navigate: NavigateFunction;
  addError: (error: string) => void;
}

class DiscordAuthComponentMain extends React.Component<ComponentProps, ComponentState> {
  playerPollingId: any
  authenticating: boolean = false

  constructor(props: any) {
    super(props)
    this.state = {
      loading: true,
    }
  }

  componentDidMount(): void {
    this.handleDiscordAuth()
  }

  handleDiscordAuth = async() => {
    if (this.authenticating) {
      return
    }
    this.authenticating = true
    try {
      const urlQury = new URLSearchParams(window.location.search)
      const state = urlQury.get('state')
      const type = urlQury.get('type')
      const error = urlQury.get('error')
      if (error) {
        this.props.addError(INVITE_CODE_ERROR_MAPPER[error] || 'Failed to authenticate with Discord')
        this.navigateAfterError(type ?? '')
        return
      }
      if(!state || ![ 'login', 'register'].includes(type ?? '')) {
        this.props.addError('Invalid Discord Auth')
        this.navigateAfterError(type ?? '')
        return
      }

      this.setState({ loadingMessage: 'Wrapping up Discord Authentication' })
      this.onDiscordAuth()
    } catch (error) {
      this.authenticating = false
      this.props.addError('An error occurred while authenticating with Discord')
      this.navigateAfterError('')
    }
  }
    
  navigateAfterError = (type: string) => {
    this.props.navigate(`/${type}`)
    this.setState({ loadingMessage: `If you don't momentarly get redirected to another page, please refresh the page` })
    setTimeout(() => {
      if (window.location.pathname !== `/${type}`) {
        window.location.href = `/${type}`
      }
    }, 2000)
  }

  onDiscordAuth = async() => {
    const urlQury = new URLSearchParams(window.location.search)
    const state = urlQury.get('state')
    const type = urlQury.get('type')
    try {
      let creds;
      if (type === 'login') {
        creds = await loginDiscord(state ?? '')
      }
      if (type === 'register') {
        creds = await registerDiscord(state ?? '')
      }
      if (!creds) {
        throw new Error()
      }
      const { access_token, refresh_token} = creds

      this.props.setAuthentication({ accessToken: access_token, refreshToken: refresh_token })
      this.props.navigate(`/`)
    } catch (error) {
      if (error instanceof FriendlyApiError) {
        this.props.addError(error.message)
      } else {
        this.props.addError('Failed to authenticate with Discord')
      }
      this.navigateAfterError(type ?? '')
    }
  }

  render = () => {
    const { loadingMessage } = this.state
    const { authenticated, authInitialized } = this.props
    if (authenticated && authInitialized) {
      return <Navigate to="/" />;
    }

    if(!authInitialized) {
      return null;
    }

    return (
      <Box sx={{ p: 5, display: 'flex', justifyContent: 'center', alignItems: 'center' }}>
        <Box sx={{ display: 'flex' }}>
          <Box sx={{ height: '100vh', maxWidth: '35rem', p: 6, display: 'flex', flexDirection: 'column', gap: 6 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center' }}>
              <Typography sx={{ mb: '2rem', textAlign: 'center' }} variant="h6">{loadingMessage}</Typography>
              <ClipLoader />
            </Box>
          </Box>
        </Box>
      </Box>
    );
    
  }
}


export const DiscordAuthComponent = connect(null, { addError })(withAuth(withSetAuth(withRouter(DiscordAuthComponentMain))))