import * as React from 'react';
import Button from '@mui/material/Button';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import { Box, SxProps, Typography } from '@mui/material';
import { useState } from 'react';
import { ServerModel } from '../../../api/server.types';
import { verifyCode } from '../../../api/player-verification';
import moment from 'moment';

export interface PlayerVerificationDialogProps {
  sx?: SxProps;
  contentSx?: SxProps;
  visible: boolean;
  onClose: () => void;
  onError: (message: string) => void;
  onPlayerVerified: () => void;
}

export const PlayerVerificationDialog = (props: PlayerVerificationDialogProps) => {
  const { onClose, onPlayerVerified, onError, visible, ...other } = props;
  const [verificationCode, setVerificationCode] = useState<string>('');

  const onVerify = React.useCallback(() => {
    if (!verificationCode) {
      onError('Please enter a verification code');
      return;
    }

    const verifyPlayer = async() => {
      try {
        setVerificationCode('');
        await verifyCode(verificationCode);
        onPlayerVerified();
      } catch(e: any) {
        onError(e.message);
      }
    };

    verifyPlayer();
  }, [visible, onError, onPlayerVerified, verificationCode]);

  const onInputChange = React.useCallback((e: React.ChangeEvent<HTMLInputElement>) => {
    setVerificationCode(e.target.value);
  }, [setVerificationCode]);


  return (
    <Dialog
      sx={{ '& .MuiDialog-paper': { width: '80%', maxHeight: '60rem' }, ...(props.sx ?? {}) }}
      maxWidth="xs"
      open={visible}
      {...other}
    >
      <DialogTitle>{'Player Verification'}</DialogTitle>
      <DialogContent sx={props.contentSx} dividers>
        <Typography>Player verification is required if you want to control your player in the game. In global or local chat, type the following to receive a verification code</Typography>
        <Typography sx={{fontWeight: 'bold'}}>
          !v
        </Typography>
        <Typography sx={{ mt: 2}}>
          When you receive the code, enter it below to verify your player.
        </Typography>
        <TextField
          sx={{ mt: 2 }}
          fullWidth
          label="Verification Code"
          value={verificationCode}
          onChange={onInputChange}
        />
      </DialogContent>
      <DialogActions sx={{ height: '5rem'}}>
        <Button onClick={onVerify} disabled={verificationCode.length <= 0}>{'Verify'}</Button>
        <Button onClick={onClose}>{'Close'}</Button>
      </DialogActions>
    </Dialog>
  );
};
