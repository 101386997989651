import React from 'react';
import { connect } from 'react-redux';

import { withAuthProps } from '../../../common/hooks/withAuth';
import { ReduxState } from '../../../reducers';
import { withRouter, WithRouterProps } from '../../../common/hooks/withParams';
import { RCONDetails, ServerDetailsModel, ServerModel, ServerSessionDTO } from '../../../api/server.types';
import { Page } from '../../../components/common/Page';
import { addError, addSuccess } from '../../../actions/notifications';
import { withSmallScreenCheck, WithSmallScreenCheckProps } from '../../../common/hooks/withSmallScreenCheck';
import { loadLocations } from '../../../actions/server-settings';
import { Box, Typography } from '@mui/material';
import { KillDeathStatsItem, ServerKillDeathStats } from '../../../api/kill-death-stats.types';
import { KillDeathStats } from './KillDeathStats';
import { getPlayerKillStats, getPlayerKillStatsForActiveLogin, getServerKillStats } from '../../../api/kill-death-stats';
import { Leaderboard } from './Leaderboard';

interface ReduxStateProps {
  session?: ServerSessionDTO;
  serverDetails?: ServerDetailsModel;
  verifiedAgids: { agid: number; name: string; }[];
  agidVerified: boolean;
}

interface ReduxActionProps {
  addError: (message: string) => void;
  addSuccess: (message: string) => void;
  loadLocations: (serverUuid: string) => void;
}

interface ComponentProps {
  server: ServerModel;
  rcon: RCONDetails;
}

interface ComponentState {
  loading: boolean;
  playerKillStatsCurrentLoginStats: KillDeathStatsItem[];
  playerKillStats: KillDeathStatsItem[];
  serverKillStats?: ServerKillDeathStats;
 }

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps & WithRouterProps & WithSmallScreenCheckProps;

class LeaderboardTabComponent extends React.Component<CompositeProps, ComponentState> {
  pollingInterval: any;
  state: ComponentState = {
    loading: true,
    playerKillStatsCurrentLoginStats: [],
    playerKillStats: [],
  }

  loadStats = async () => {
    try {
        const { [0]: playerKillStats, [1]: playerKillStatsCurrentLoginStats, [2]: serverKillStats } = await Promise.all([
            getPlayerKillStats(this.props.serverDetails?.server?.uuid ?? ''),
            getPlayerKillStatsForActiveLogin(this.props.serverDetails?.server?.uuid ?? ''),
            getServerKillStats(this.props.serverDetails?.server?.uuid ?? ''),
        ]);
    
        this.setState({
            playerKillStats,
            playerKillStatsCurrentLoginStats,
            serverKillStats,
            loading: false,
        });
    } catch (e) {
        this.props.addError('An error occurred while loading the leaderboard stats.');
        this.setState({
            loading: false,
        });
    }
        
  }

    async componentDidMount() {
        this.loadStats();
    }


  render() {
    const { serverDetails, verifiedAgids, agidVerified } = this.props;
    const { playerKillStatsCurrentLoginStats, playerKillStats, serverKillStats, loading } = this.state;
    if (!serverDetails?.server) {
      return null;
    }
    return (
      <Page>
        {verifiedAgids.length === 0 && (
            <Typography variant="h5" sx={{ textAlign: 'center' }}>
              In order to view the leaderboard, you must verify your player in the server. You can do this from the <strong>Players</strong> tab.
            </Typography>
        )}
        {!loading && agidVerified && (
            <Box>
                <Typography variant="h5">Overall Stats</Typography>
                <Box sx={{ marginBottom: '5rem' }}>
                    {playerKillStats.map((item: KillDeathStatsItem) => (
                        <KillDeathStats title={`Overall Stats`} item={item} />
                    ))}
                    {playerKillStats.length === 0 && (
                        <Typography variant="h6">
                            You don't have any stats yet.
                        </Typography>
                    )}
                </Box>
                <Typography variant="h5">Latest Login Stats</Typography>
                <Box sx={{ marginBottom: '5rem' }}>
                    {playerKillStatsCurrentLoginStats.map((item: KillDeathStatsItem) => (
                        <KillDeathStats title={`Latest Login Stats`} item={item} />
                    ))}
                    {playerKillStatsCurrentLoginStats.length === 0 && (
                        <Typography variant="h6">
                            You are not currently logged in to the server.
                        </Typography>
                    )}
                </Box>
                <Typography variant="h5">Server Leaderboard</Typography>
                <Leaderboard data={serverKillStats?.scoreboard} />
            </Box>            
        )}
      </Page>
    )
  }
}


const mapStateToProps = ({ servers, serverSettings }: ReduxState) => {
  const { serverDetail } = servers;
  return {
    session: serverDetail?.session,
    serverDetails: servers.serverDetail,
    verifiedAgids: serverDetail?.verifiedAgids ?? [],
    agidVerified: serverDetail?.agidVerified ?? false,
  }
};

export const LeaderboardTab = withSmallScreenCheck(withRouter(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { addError, addSuccess, loadLocations })(LeaderboardTabComponent)));