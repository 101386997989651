import { Component } from 'react';
import { VoiceCommandCard } from './VoiceCommandCard';
import axios, { AxiosResponse } from 'axios';

interface ServerVoiceCommandProps {
 serverUuid: string;
}

export class ServerVoiceCommand extends Component<
  ServerVoiceCommandProps
> {


  processCommandResponse = (response: AxiosResponse): string | Buffer => {
    const contentType = response.headers['content-type'];

    if (contentType && contentType.includes('audio/mpeg')) {
      return response.data;
    } 
    
    const { data } = response;

    return data.response;
  }

  onRecordingCaptured = async (blob: Blob) => {
    const { serverUuid } = this.props;
    try {
      const RESPONSE_FORMAT: 'audio' | 'text' = 'audio';
      const formData = new FormData();
      formData.append('file', blob, 'recording.webm');
      formData.append('format', RESPONSE_FORMAT);

      const response = await axios.post(`/api/server/${serverUuid}/voice-command`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data',
        },
        responseType: RESPONSE_FORMAT === 'audio' ? 'arraybuffer' : undefined,
      });

      const commandResponse = this.processCommandResponse(response);

      return commandResponse;
    } catch (error) {
      console.error('Error uploading audio:', error);

      return 'An error occurred while processing your command.';
    }
  }

  render() {
    return (
      <VoiceCommandCard
        onRecordingCaptured={this.onRecordingCaptured}
      />
    );
  }
}
