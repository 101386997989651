import React from 'react';
import { GrowthStages } from '../../../api/server-players.types';
import { Box, Button, TextField } from '@mui/material';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { updateRespawnMarks } from '../../../actions/server-settings';
import { ServerModel } from '../../../api/server.types';

interface ReduxStateProps {
  server: ServerModel
  respawn_marks: number | null;
}
interface ReduxActionProps {
  updateRespawnMarks: (serverUuid: string, marks: number | null) => void;
}

interface ComponentProps {
}

interface State {
  respawnMarks: number | null;
}

type Props = ComponentProps & ReduxStateProps & ReduxActionProps;

class RespawnMarksComponent extends React.Component<Props, State> {
  constructor(props: Props) {
    super(props);
    this.state = {
      respawnMarks: props.respawn_marks || null,
    };
  }

  componentDidUpdate(prevProps: Readonly<Props>, prevState: Readonly<State>, snapshot?: any): void {
    if (prevProps.respawn_marks !== this.props.respawn_marks) {
      this.setState({ respawnMarks: this.props.respawn_marks || null });
    }
  }

  onMarksChanged = (event: React.ChangeEvent<HTMLInputElement>) => {
      if (event.target.value === '') {
          this.setState({ respawnMarks: null });
          return;
      }
      const seconds = parseInt(event.target.value);
      if (isNaN(seconds)) {
          return;
      }

      this.setState({ respawnMarks: seconds });
  }

  onUpdateMarks = () => {
    const { respawnMarks } = this.state;
    const { server, updateRespawnMarks } = this.props;

    updateRespawnMarks(server.uuid, respawnMarks);
  }

  render = () => {
    const { respawnMarks  } = this.state;

    return (
      <Box sx={{ typography: 'body1', mt: 3, fontFamily: '"Courier New", Courier, monospace', maxWidth: '50rem' }}>
        <Box sx={{ display: 'flex', alignItems: 'center', marginTop: '1rem'}}>
          <TextField
            sx={{ width: '20rem' }}
            label="Respawn Marks"
            type="number"
            value={respawnMarks}
            onChange={this.onMarksChanged}
          />
          {respawnMarks !== this.props.respawn_marks && (<Button sx={{ marginLeft: '1rem', padding: '1rem' }} onClick={this.onUpdateMarks}>Update Marks</Button>)}
        </Box>
      </Box>
    );
  };
}

const mapStateToProps = ({ serverSettings, servers }: ReduxState) => {
  const { serverDetail } = servers;
  const { respawn_marks } = serverSettings[serverDetail?.server?.uuid ?? ''] ?? { respawn_marks: 0 };

  return {
    server: serverDetail?.server,
    respawn_marks,
  };
}

export const RespawnMarks = connect(mapStateToProps, { updateRespawnMarks })(RespawnMarksComponent);