import axios from 'axios'

import { FriendlyApiError, GenericError } from './types'
import { VerificationCode } from './player-verification.types'

const VERIFICATION_ENDPOINT = `/api/alderon/verification`
const VERIFY_ENDPOINT = `${VERIFICATION_ENDPOINT}/verify`

export const getVerificationStatus = async(): Promise<boolean> => {
    try {
        const { data } = await axios.get(VERIFICATION_ENDPOINT)
        const { success, verified } = data

        if (!success) {
            throw new GenericError('Failed to retrieve servers for user')
        }

        return verified
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}

export const verifyCode = async(code: string): Promise<void> => {
    try {
        const { data } = await axios.post(VERIFY_ENDPOINT, { code })
        const { success } = data

        if (!success) {
            throw new GenericError('Failed to retrieve verification code')
        }
    } catch(e: any) {
        if (e.request.status === 400) {
            throw new FriendlyApiError(e)
        }

        throw e
    }
}