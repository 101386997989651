import React from 'react';
import { connect } from 'react-redux';
import { Box, Typography } from '@mui/material';
import ClipLoader from "react-spinners/ClipLoader";
import { withAuth, withAuthProps } from '../../common/hooks/withAuth';
import { ReduxState } from '../../reducers';
import { User } from '../../api';
import { Page } from '../common/Page';
import { ServerModel } from '../../api/server.types';
import ServerList from '../servers/ServerList';
import { getServers, createServer } from '../../actions/server';
import AddServer from '../servers/AddServer';
import { withRouter, WithRouterProps } from '../../common/hooks/withParams';
import { ServerInvitationDetails } from '../../api/server-invitations.types';
import { clearServerInvitationDetails } from '../../actions/server-invites';
import { joinServerFromInvite } from '../../api/server-invitations';
import { addError } from '../../actions/notifications';
import { ConfirmationDialog } from '../common/Modal';
import ServerInfoCard from '../servers/common/ServerInfoCard';
import StormyBackground from '../servers/common/weather/StormyBackground';
import { KillDeathStats } from '../servers/kill-deaths/KillDeathStats';

interface ReduxStateProps {
  scope: string;
  isRoot: boolean;
  isAdmin: boolean;
  hasAlderonScope: boolean;
  servers: ServerModel[];
  invitation?: ServerInvitationDetails;
  loadingServer: boolean;
}

interface ReduxActionProps {
  addError: (message: string) => void;
  getServers: () => void;
  clearServerInvitationDetails: () => void;
  createServer: (serverData: { name: string; description: string; logo: File | null }) => void;
 }

interface ComponentProps {}

interface HomeState {
  showInviteModal: boolean;
}

export type HomeProps = ComponentProps & ReduxStateProps & ReduxActionProps & withAuthProps & WithRouterProps;

class Home extends React.Component<HomeProps, HomeState> {
  state = {
    showInviteModal: false,
  }

  componentDidMount(): void {
      this.props.getServers();
      if (this.props.invitation) {
        this.setState({ showInviteModal: true });
      }
  }

  componentDidUpdate(prevProps: Readonly<HomeProps>, prevState: Readonly<HomeState>, snapshot?: any): void {
    if (this.props.invitation && this.props.invitation !== prevProps.invitation) {
      this.setState({ showInviteModal: true });
    }
  }

  onShowInviteModal = () => {
    this.setState({ showInviteModal: true });
  }

  onCloseInviteModal = () => {
    this.props.clearServerInvitationDetails();
    this.setState({ showInviteModal: false });
  }

  onJoinServer = async() => {
    this.onCloseInviteModal();
    try {
      const server = await joinServerFromInvite(this.props.invitation?.invitation?.invitation_code ?? '');
      if (!server) {
        throw new Error('Failed to join server');
      }
      
      this.props.navigate(`/server/${server.uuid}`); 
    } catch (e: any) {
      if (e.userFriendly) {
          this.props.addError(e.message);
      } else {
        this.props.addError('An error occurred while retrieving the invitation details, please try again.');
      }
    }
  }

  onAddNewServer = (serverData: { name: string; description: string; logo: File | null }) => {
    this.props.createServer(serverData);
  }

  onServerClicked = (serverUuid: string) => {
    this.props.navigate(`/server/${serverUuid}`);
  }

  onDeleteServer = (serverUuid: string) => {
    this.props.navigate(`/server/${serverUuid}/admin/delete`);
  }

  render = () => {
    const { servers, invitation, loadingServer } = this.props;
    const { showInviteModal } = this.state;
    const { server: invitationServer, invitation: invite } = invitation ?? {};
    
    return (
      <Page>
          <ConfirmationDialog
            id={'invite-modal'}
            title={`${invitationServer?.name ?? 'Server'} Invitation`}
            visible={showInviteModal}
            onConfirmed={this.onJoinServer}
            onClose={this.onCloseInviteModal}>
              <Typography>
                You have been invited to join {invitationServer?.name}.
              </Typography>
              <Typography>
                Would you like to join?
              </Typography>
          </ConfirmationDialog>
          <Box sx={{ p: 5 }}>
            <Box sx={{ display: 'flex', flexDirection: 'column', alignItems: 'center', justifyContent: 'center' }}>
              <Box sx={{ fontSize: '1.5rem', fontWeight: 'bold', mb: 2 }}>Welcome to TitanDash</Box>
              <Box sx={{ fontSize: '1rem', textAlign: 'center', width: '100%' }}>
                {loadingServer && <ClipLoader />}
                {!loadingServer && !servers.length && 'You aren\'t part of any servers, go ahead and add one'}
                {!loadingServer && servers.length > 0 && <ServerList servers={servers} onServerClicked={this.onServerClicked} onDeleteServer={this.onDeleteServer} />}
                {/* <ServerInfoCard
                  data={{
                    server_name: "HungryGamez",
                    map: "Panjura",
                    weather: "ClearSky",
                    time_of_day: "19:51",
                    up_time: "2 hours 7 mins",
                    restarts_in: "52 mins",
                    server_start_time: "2025-02-01T00:11:08.000Z",
                    server_restart_time: "2025-02-01T00:11:06.000Z",
                  }}
                /> */}
                <AddServer onAddNewServer={this.onAddNewServer} />
              </Box>
            </Box>
          </Box>
      </Page>
    )
  }
}


const mapStateToProps = ({ auth, servers }: ReduxState) => {
  const { user } = auth;
  const { scope } = user as User ?? {};
  const isRoot = scope?.includes('titandash/root');
  const isAdmin = scope?.includes('titandash/admin');
  const hasAlderonScope = scope?.includes('titandash/alderon');

  return {
    scope,
    isRoot,
    isAdmin,
    hasAlderonScope,
    servers: servers.items,
    invitation: servers.invitation,
    loadingServer: servers.loadingServers,
  }
};

export const HomeComponent = withRouter(withAuth(connect(mapStateToProps, { getServers, createServer, clearServerInvitationDetails, addError })(Home)));