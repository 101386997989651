import React from 'react';
import { Box } from '@mui/material';
import {
  DataGrid,
  GridColDef,
  GridRenderCellParams,
  GridSortDirection,
  GridSortItem,
  GridSortModel,
} from '@mui/x-data-grid';

// 1. Define the interface for a single player’s data
export interface LeaderboardItem {
  agid: number;
  displayName: string;
  kills: number;
  deaths: number;
  ratio: number;
}

// 2. Define the props for the Leaderboard, expecting a record keyed by player name
type LeaderboardProps = {
  data?: Record<string, LeaderboardItem>;
};

type LeaderboardState = {
  pageSize: number;
  page: number;
  sortModel: GridSortItem[];
};

export class Leaderboard extends React.Component<LeaderboardProps, LeaderboardState> {
  constructor(props: LeaderboardProps) {
    super(props);
    this.state = {
      // Initial number of rows per page
      pageSize: 10,
      page: 0,
      sortModel: [{
        field: 'kills',
        sort: 'desc'
      }]
    };
  }

  // 3. Define columns
  //    - Make columns sortable by default with `sortable: true`
  //    - Use `flex: 1` or a fixed `width` so columns size nicely
  //    - If you want to format ratio to two decimals, use valueFormatter
  columns: GridColDef[] = [
    {
      field: 'displayName',
      headerName: 'Display Name',
      sortable: true,
      minWidth: 200,
      flex: 1
    },
    {
      field: 'kills',
      headerName: 'Kills',
      sortable: true,
      width: 100
    },
    {
      field: 'deaths',
      headerName: 'Deaths',
      sortable: true,
      width: 100
    },
    {
      field: 'ratio',
      headerName: 'K/D Ratio',
      sortable: true,
      width: 100,
      valueFormatter: (params: any) => {
        return params?.toFixed(2) ?? params?.value ?? params;
      }
    }
  ];

  render() {
    // 4. Transform the `data` prop (Record<string, LeaderboardItem>)
    //    into an array of row objects suitable for the DataGrid.
    //    We must assign a unique `id` property to each row.
    const rows = Object.values(this.props.data || {}).map((item) => ({
      id: item.agid, // Use agid as the unique row id
      ...item
    }));

    return (
      <Box
        sx={{
          width: '100%',
          height: '39.5rem',
          maxWidth: '62rem',
          '& .MuiDataGrid-columnHeader:focus': {
            outline: 'none !important'
          },
          '.MuiDataGrid-columnHeader:focus-within': {
            outline: 'none !important'
          },
          '& .css-1r7svyj-MuiDataGrid-root .MuiDataGrid-cell:focus-within': {
            outline: 'none !important'
          }
        }}
      >
        <DataGrid
          rows={rows}
          columns={this.columns}
          paginationModel={{
            pageSize: this.state.pageSize,
            page: this.state.page
          }}
          onPaginationModelChange={(paginationModel) => {
            this.setState({
              pageSize: paginationModel.pageSize,
              page: paginationModel.page
            });
          }}
          sortModel={this.state.sortModel}
          onSortModelChange={(sortModel) => {
            this.setState({
                sortModel,
            });
          }}
          pageSizeOptions={[10, 25, 50, 100]}
          pagination
          disableRowSelectionOnClick
        />
      </Box>
    );
  }
}
