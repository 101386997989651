import React from 'react';
import {
  Box,
  Card,
  CardContent,
  CardActions,
  Typography,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Divider,
  Collapse,
  IconButton
} from '@mui/material';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import ExpandLessIcon from '@mui/icons-material/ExpandLess';
import DownloadIcon from '@mui/icons-material/Download';
import html2canvas from 'html2canvas';
import { KillDeathStatsItem } from '../../../api/kill-death-stats.types';

type Props = {
  title: string;
  item: KillDeathStatsItem;
};

type State = {
  showTables: boolean;
  screenShotting?: boolean;
};

export class KillDeathStats extends React.Component<Props, State> {
  // We'll store a ref to the <Card> so we can screenshot it
  private cardRef: React.RefObject<HTMLDivElement>;

  constructor(props: Props) {
    super(props);

    // Default state: collapsed
    this.state = {
      showTables: false
    };

    // Create a ref to the Card container for screenshots
    this.cardRef = React.createRef<HTMLDivElement>();
  }

  toggleCollapse = () => {
    this.setState((prevState) => ({
      showTables: !prevState.showTables
    }));
  };
  

  // Method that uses html2canvas to capture a screenshot and download it
  handleDownloadScreenshot = async () => {
    const { title, item } = this.props;
    this.setState({ screenShotting: true }, async () => {
      try {
        if (!this.cardRef.current) return;
    
        const canvas = await html2canvas(this.cardRef.current);
        const dataUrl = canvas.toDataURL('image/png');

        // Create a link and trigger the download
        const link = document.createElement('a');
        link.download = `${item.displayName}-${title}-${Date.now()}.png`;
        link.href = dataUrl;
        link.click();
      } catch (error) {
        console.error('Screenshot download failed:', error);
      } finally {
        this.setState({ screenShotting: false });
      }
    });
  };

  renderListTable(list: { [key: number]: number }) {
    const entries = Object.entries(list).slice(0, 10);

    return (
      <TableContainer sx={{ maxHeight: '80rem' }}>
        <Table stickyHeader aria-label="kill-death-list">
          <TableHead>
            <TableRow>
              <TableCell sx={{ fontWeight: 'bold' }}>Player</TableCell>
              <TableCell sx={{ fontWeight: 'bold' }}>Deaths</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {entries.map(([player, deaths]) => (
              <TableRow key={player}>
                <TableCell>{player}</TableCell>
                <TableCell>{deaths}</TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    );
  }

  render() {
    const { kills, deaths, ratio, nemesis, killedMost, dates } = this.props.item;
    const { start_date, end_date } = dates || {};
    const { showTables } = this.state;

    return (
      <Card
        sx={{
          display: 'flex',
          flexDirection: 'column',
          maxWidth: '62rem',
          minWidth: this.state.screenShotting ? '31rem' : undefined,
          width: '100%',
          position: 'relative'
        }}
        // Attach the ref here so we screenshot the entire card
        ref={this.cardRef}
      >
        {/* Download button in the top-right corner */}
        
        {!this.state.screenShotting && (
          <IconButton
            onClick={this.handleDownloadScreenshot}
            sx={{
              position: 'absolute',
              top: 8,
              right: 8,
              zIndex: 1
            }}
            aria-label="download-screenshot"
          >
            <DownloadIcon />
          </IconButton>
        )}

        <CardContent
          sx={{
            display: 'flex',
            flexDirection: 'column',
            gap: 2
          }}
        >
          <Typography variant="h6" sx={{ fontWeight: 'bold', marginRight: '1rem' }}>
            Kills / Deaths {this.props.title ? `- ${this.props.item.displayName}` : ''}
          </Typography>
          {this.props.title && (
            <Typography variant="body1" sx={{ fontWeight: 'bold' }}>
              {this.props.title}
            </Typography>
          )}
          <Divider sx={{ my: 1 }} />

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
          >
            <Box>
              <Typography component="span" sx={{ fontSize: 18, fontWeight: 'bold' }}>
                Kills:{' '}
              </Typography>
              <Typography component="span">{kills}</Typography>
            </Box>
            <Box>
              <Typography component="span" sx={{ fontSize: 18, fontWeight: 'bold' }}>
                Deaths:{' '}
              </Typography>
              <Typography component="span">{deaths}</Typography>
            </Box>
            <Box>
              <Typography component="span" sx={{ fontSize: 18, fontWeight: 'bold' }}>
                K/D Ratio:{' '}
              </Typography>
              <Typography component="span">{ratio.toFixed(2)}</Typography>
            </Box>
          </Box>

          <Box
            sx={{
              display: 'flex',
              flexDirection: 'row',
              gap: 2,
              alignItems: 'center',
              justifyContent: 'space-around'
            }}
          >
            <Box>
              <Typography component="span" sx={{ fontSize: 18, fontWeight: 'bold' }}>
                Nemesis:{' '}
              </Typography>
              <Typography component="span">{nemesis?.displayName || '--'}</Typography>
            </Box>
            <Box>
              <Typography component="span" sx={{ fontSize: 18, fontWeight: 'bold' }}>
                Killed The Most:{' '}
              </Typography>
              <Typography component="span">{killedMost?.displayName || '--'}</Typography>
            </Box>
          </Box>
          {/* {start_date && end_date && (
            <>
              <Typography variant="body2" sx={{ fontWeight: 'bold' }}>
                Date Range:
              </Typography>
              <Typography variant="body1">
                Start: {start_date}
                <br />
                End: {end_date}
              </Typography>
            </>
          )} */}
        </CardContent>

        {/* Full-width clickable area to toggle */}
        {!this.state.screenShotting && (
            <CardActions
              onClick={this.toggleCollapse}
              sx={{
                width: '100%',
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
                backgroundColor: '#f3f3f3',
                cursor: 'pointer',
                userSelect: 'none',
                py: 1
              }}
            >
              <IconButton
                sx={{
                  transform: showTables ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                {showTables ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
              <Typography variant="body1" sx={{ textAlign: 'center' }}>
                {showTables ? 'Hide Kill/Death Counts' : 'Show Kill/Death Counts'}
              </Typography>
              <IconButton
                sx={{
                  transform: showTables ? 'rotate(180deg)' : 'rotate(0deg)',
                  transition: 'transform 0.3s'
                }}
              >
                {showTables ? <ExpandLessIcon /> : <ExpandMoreIcon />}
              </IconButton>
            </CardActions>
        )}

        {/* Collapsible Section for Top 10 Tables */}
        <Collapse in={showTables} timeout="auto" unmountOnExit sx={{ overflowX: 'scroll' }}>
          <CardContent sx={{ display: 'flex', flexDirection: 'row', gap: 2 }}>
            <CardContent sx={{ p: 0, flex: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Top 10 You Killed
              </Typography>
              {this.renderListTable(this.props.item.kills_list)}
            </CardContent>

            <CardContent sx={{ p: 0, flex: 1 }}>
              <Typography variant="h6" sx={{ fontWeight: 'bold', mb: 2 }}>
                Top 10 Who Killed You
              </Typography>
              {this.renderListTable(this.props.item.deaths_list)}
            </CardContent>
          </CardContent>
        </Collapse>
      </Card>
    );
  }
}
