import * as React from 'react';
import { Box, Tabs, Tab, Typography, Link } from '@mui/material';
import { Outlet } from 'react-router-dom';
import ClipLoader from "react-spinners/ClipLoader";
import { ServerModel, TabItem, TAB_SECTIONS } from '../../../api/server.types';
import { withRouter, WithRouterProps } from '../../../common/hooks/withParams';
import { connect } from 'react-redux';
import { ReduxState } from '../../../reducers';
import { loadServerDetails } from '../../../actions/server';
import { withSmallScreenCheck, WithSmallScreenCheckProps } from '../../../common/hooks/withSmallScreenCheck';
import { ServerVoiceCommand } from '../common/ServerVoiceCommand';

interface ReduxStateProps {
  server?: ServerModel;
}

interface ReduxActionProps {
  loadServerDetails: (serverUuid: string) => void;
}

interface ComponentProps {
  hideTabs?: boolean;
}

interface ComponentState {
  sectionIndex?: number;
  tabIndex?: number;
  sections: { name: string, items: TabItem[] }[];
}

type CompositeProps = ComponentProps & ReduxStateProps & ReduxActionProps & WithRouterProps & WithSmallScreenCheckProps;


class ServerHome extends React.Component<CompositeProps, ComponentState> {
  state = {
    sectionIndex: undefined,
    tabIndex: undefined,
    sections: [] as { name: string, items: TabItem[] }[],
  }

  hasPermission = (permissions: string[], required: string[]) => {
    return required.some((permission) => permissions.includes(permission));
  }
  
  filterTabs = (permissions: string[]) => {
    const filteredTabSections = TAB_SECTIONS.map((section) => {
      const items = (section.items as TabItem[]).filter((tab) => this.hasPermission(permissions, tab.permissions));
      return { ...section, items };
    });
    return filteredTabSections.filter((section) => section.items.length > 0);
  }

  updateTabs = () => {
    const { server } = this.props;
    if (!server) {
      return;
    }

    const sections = this.filterTabs(server.permissions);
    const currentEndpoint = window.location.pathname.split('/').pop();

    
    if (this.props.isSmallScreen) {
      const flattenedTabs = sections.flatMap((section) => section.items);
      let tabIndex = flattenedTabs.findIndex((tab) => tab.endpoint === currentEndpoint);
      if (tabIndex === -1) {
        tabIndex = 0;
      }
      this.setState({ sections, sectionIndex: 0, tabIndex });
      return;  
    }

    let sectionIndex = sections.findIndex((section) => section.items.some((tab) => tab.endpoint === currentEndpoint));
    if (sectionIndex === -1) {
      sectionIndex = 0;
    }

    let tabIndex = sections[sectionIndex].items.findIndex((tab) => tab.endpoint === currentEndpoint);
    if (sectionIndex === -1 && tabIndex === -1) {
      sectionIndex = 0;
      tabIndex = 0;
    } else if (tabIndex === -1) {
      tabIndex = 0;
    }
    this.setState({ sections, sectionIndex, tabIndex });
  }

  navigateToTab = (section: number, tabIndex: number) => {
    const { server, navigate, isSmallScreen } = this.props;
    if (!server) {
      return;
    }
    if (isSmallScreen) {
      const flattenedTabs = this.state.sections.flatMap((section) => section.items);
      const item = flattenedTabs[tabIndex];
      const endpoint = item.endpoint === 'exit' ? `/server/${server?.uuid}/home` : item.endpoint;
      navigate(endpoint);

      return;
    }

    const item = this.state.sections[section].items[tabIndex];
    const endpoint = item.endpoint === 'exit' ? `/server/${server?.uuid}/home` : item.endpoint;

    navigate(endpoint);
  }

  componentDidMount(): void {
    this.loadServerDetails();
  }

  componentDidUpdate(prevProps: Readonly<CompositeProps>, prevState: Readonly<ComponentState>, snapshot?: any): void {
    const { server } = this.props;
    const { server: prevServer } = prevProps;

    if (this.props.params.serverUuid !== prevProps.params.serverUuid) {
      this.loadServerDetails();
    }

    if (server?.uuid !== prevServer?.uuid || server?.permissions !== prevServer?.permissions) {
      this.updateTabs();
    }

    if (this.state.tabIndex !== prevState.tabIndex || this.state.sectionIndex !== prevState.sectionIndex) {
      this.navigateToTab(this.state.sectionIndex ?? 0, this.state.tabIndex ?? 0);
    }

    if (this.props.isSmallScreen !== prevProps.isSmallScreen) {
      this.updateTabs();
    }
  }

  loadServerDetails = async() => {
    const { params } = this.props;
    const serverUuid = params.serverUuid;
    this.props.loadServerDetails(serverUuid);
  }

  handleChange = (sectionIndex: number, tabIndex: number) => {
    const { server } = this.props;
    const serverUuid = server?.uuid;
    if (!serverUuid) {
      return;
    }

    this.setState({ sectionIndex, tabIndex });
  };

  render() {
    const { tabIndex, sections } = this.state;
    const { server, isSmallScreen, hideTabs } = this.props;
    if (!server) {
      return (
        <Box sx={{ display: 'flex', flex: 1, justifyContent: 'center', alignItems: 'center', padding: '10rem' }}>
          <ClipLoader />
        </Box>
      );
    }
    
    if (hideTabs) {
      return (
          <Box
            sx={{
              display: 'flex',
              flexDirection: isSmallScreen ? 'column' : 'row',
              height: '100vh',
            }}>
          <Box sx={{ flexGrow: 1, p: 3 }}>
            <Outlet />
          </Box>
          <ServerVoiceCommand
            serverUuid={server.uuid}
          />
        </Box>
      );
    }
    return (
      <Box
        sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'column' : 'row',
          height: '100vh',
        }}
      >
        <Box sx={{
          display: 'flex',
          flexDirection: isSmallScreen ? 'row' : 'column',
          borderRight: isSmallScreen ? 0 : 1,
          borderBottom: isSmallScreen ? 1 : 0,
          borderColor: 'divider', 
        }}>
        {!isSmallScreen && sections.map((section, sectionIndex) => {
          return (
            <Box sx={{ display: 'flex', flexDirection: 'column', width: isSmallScreen ? '100%' : '200px' }}>
              <Typography sx={{ pl: 2, pt: 2, fontSize: '13pt', color: '#0008', fontWeight: 'bold' }}>{section.name}</Typography>
                <Tabs
                  orientation={'vertical'}
                  value={this.state.sectionIndex === sectionIndex ? this.state.tabIndex : false}
                  onChange={(_, newValue) => this.handleChange(sectionIndex, newValue)}
                  aria-label="Server details tabs"
                  sx={{
                    padding: 0,
                    paddingLeft: 2,
                  }}
                >
                  {section.items.map((tab) => (
                    <Tab key={tab.label} label={tab.label} sx={{ alignItems: 'flex-start' }} />
                  ))}
                </Tabs>
            </Box>
          )
        })}
        {isSmallScreen &&
            <Box sx={{ display: 'flex', flexDirection: 'column', width: isSmallScreen ? '100%' : '200px' }}>
                <Tabs
                  orientation={'horizontal'}
                  variant="scrollable"
                  value={this.state.tabIndex}
                  onChange={(_, newValue) => this.handleChange(0, newValue)}
                  aria-label="Server details tabs"
                  sx={{
                    padding: 0,
                    paddingLeft: 2,
                  }}
                >
                  {sections.map((section) => (
                    section.items.map((tab) => (
                      <Tab key={tab.label} label={tab.label} sx={{ alignItems: 'flex-start' }} />
                    ))
                  ))}
                </Tabs>
            </Box>
      }
        </Box>
        <Box sx={{ flexGrow: 1, p: 3 }}>
          <Outlet />
        </Box>
        <ServerVoiceCommand
          serverUuid={server.uuid}
        />
      </Box>
    );
  }
}


const mapStateToProps = ({ servers }: ReduxState) => {
  const server = servers?.serverDetail?.server;
  return {
    server,
  }
};

export const ServerHomeComponent = withSmallScreenCheck(withRouter(connect<ReduxStateProps, ReduxActionProps, ComponentProps, ReduxState>(mapStateToProps, { loadServerDetails })(ServerHome)));